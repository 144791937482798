import styled from 'styled-components'
import colors from 'styled/colors'
import Container from 'layout/Container'
import Section from 'layout/Section'
import CardGrid, { CardPropType } from 'components/CardGrid'

const Wrap = styled.div<{ hasImage?: boolean }>`
  background-color: ${colors.ctaOrange};
  color: ${colors.dark};
  padding-bottom: 1px;

  ${(props) =>
    !props.hasImage &&
    `
    position: relative;
    padding-top: 1px;
    z-index: 1;
  `}

  section {
    color: currentColor;
  }
`

const Image = styled.div`
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    background-color: ${colors.dark};
    z-index: -1;
  }
`

const StyledCardGrid = styled(CardGrid)`
  position: relative;
  z-index: 0;

  .col-xs-12:nth-child(2) > div {
    background-color: #f3f7fb;
  }

  .col-xs-12:nth-child(3) > div {
    background-color: #cdf4ed;
  }
`

type PropsType = {
  image?: string
  title: string
  subtitle?: string
  cards: CardPropType[]
}

const OrangeIntro = ({ image, title, subtitle, cards }: PropsType) => (
  <Wrap hasImage={!!image}>
    {image && (
      <Image>
        <Container narrow>
          <img src={image} alt="" />
        </Container>
      </Image>
    )}
    <Section
      title={title}
      subtitle={subtitle}
      classNames={{
        header: 'section__header',
      }}
    >
      <StyledCardGrid cards={cards} />
    </Section>
  </Wrap>
)

export default OrangeIntro
