const colors = {
  white: '#fff',
  black: '#000',
  dark: '#252839',
  purple: '#464c6c',
  purpleTransparent: 'rgba(70, 76, 108, 0.5)',

  paleYellow: '#fff9ed',
  veryLightGray: '#F3F7FB',
  darkOrange: '#D29309',

  ctaRed: '#f00a0a',
  ctaGreen: '#03cf2a',
  ctaOrange: '#f0a80a',
  ctaOrangeHover: '#a87507',
  ctaOrangeHoverLight: '#ffd476',
  ctaAqua: '#49c5b6',
  ctaAquaHover: '#33887e',
  ctaAquaHoverLight: '#cdf4ed',
}

export default colors
