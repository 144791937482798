import styled from 'styled-components'
import Header from 'layout/Header'
import Home from 'views/Home'
import TOS from 'views/TOS'
import About from 'views/About'
import Pricing from 'views/Pricing'
import Features from 'views/Features'
import Privacy from 'views/PrivacyPolicy'
import DocumentTitle from 'react-document-title'

const Wrap = styled.div`
  overflow: hidden;
`

type WrappedPageProps = {
  title: string
  children: React.ReactNode
}

const WrappedPage = ({ title, children }: WrappedPageProps) => {
  return (
    <DocumentTitle title={title}>
      <Wrap>
        <Header />
        {children}
      </Wrap>
    </DocumentTitle>
  )
}

export const PATHS = {
  HOME: '/',
  ABOUT: '/about',
  PRICING: '/pricing',
  FEATURES: '/features',
  PRIVACY: '/privacy-policy',
  TERMS: '/terms-of-service',
}

const routes = [
  {
    path: PATHS.HOME,
    title: 'Home',
    component: (
      <WrappedPage title="Switchboard">
        <Home />
      </WrappedPage>
    ),
  },
  {
    path: PATHS.ABOUT,
    title: 'About',
    component: (
      <WrappedPage title="Switchboard">
        <About />
      </WrappedPage>
    ),
  },
  {
    path: PATHS.PRICING,
    title: 'Pricing',
    component: (
      <WrappedPage title="Switchboard">
        <Pricing />
      </WrappedPage>
    ),
  },
  {
    path: PATHS.FEATURES,
    title: 'Features',
    component: (
      <WrappedPage title="Switchboard">
        <Features />
      </WrappedPage>
    ),
  },
  {
    path: PATHS.PRIVACY,
    title: 'Privacy Policy',
    component: <Privacy />,
  },
  {
    path: PATHS.TERMS,
    title: 'Terms of Service',
    component: <TOS />,
  },
]

export default routes
