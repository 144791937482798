import Container from 'layout/Container'
import styled from 'styled-components'
import { TitleMammoth, TitleSmall } from 'components/Typography'
import colors from 'styled/colors'
import mq from 'styled/mediaQuery'

const Wrap = styled.div`
  position: relative;
  color: ${colors.white};
  text-align: center;
  z-index: 1;
  padding-top: 50px;
  padding-bottom: 50px;

  ${mq.from(mq.devices.tablet)} {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  + section {
    margin-top: 0;
  }
`

const SContainer = styled(Container)`
  position: relative;
`

const LinesImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1440px;
  height: 800px;
  z-index: -1;
  pointer-events: none;
  max-width: none;
  transform: translate(-50%, -50%);

  ${mq.to(mq.devices.tablet)} {
    top: 20em;
  }

  ${mq.from(mq.devices.desktop)} {
    top: 23em;
  }

  ${mq.from(mq.devices.desktopLg)} {
    width: 100vw;
    height: auto;
  }
`

const Title = styled(TitleMammoth)`
  max-width: 90%;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.3;

  &:last-child {
    margin-bottom: 0;
  }

  ${mq.from(mq.devices.desktop)} {
    font-size: 62px;
  }
`

const Subtitle = styled(TitleSmall)`
  max-width: 820px;
  margin-bottom: 0.5em;
  line-height: 1.5;

  ${mq.from(mq.devices.tablet)} {
    font-size: 24px;
  }
`

const CTA = styled.div`
  margin-top: 30px;
`

type PropsType = {
  title: string | React.ReactNode
  cta?: string | React.ReactNode
  subtitle?: string
  withIllustration?: boolean
}

const Hero = ({ title, subtitle, cta, withIllustration }: PropsType) => (
  <Wrap>
    {withIllustration ? (
      <LinesImage src="/images/hero-network-bg.svg" alt="" />
    ) : null}
    <SContainer>
      <Title>{title}</Title>
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
      {cta ? <CTA>{cta}</CTA> : null}
    </SContainer>
  </Wrap>
)

export default Hero
