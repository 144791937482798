import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import ScrollToTop from 'components/ScrollTop'
import routes from './routes'

const App = () => (
  <Router>
    <ScrollToTop>
      <Routes>
        {routes.map((route, idx) => (
          <Route key={idx} path={route.path} element={route.component} />
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ScrollToTop>
  </Router>
)

export default App
