import React from 'react'
import styled from 'styled-components'
import mq from 'styled/mediaQuery'
import colors from 'styled/colors'
import Section from 'layout/Section'
import Container from 'layout/Container'
import InlineSVG from 'components/InlineSVG'
import data from './data'

const Wrap = styled.div`
  color: ${colors.dark};

  ${mq.from(mq.devices.tablet)} {
    display: grid;
    grid-template-rows: auto auto;
    grid-column-gap: 16px;
  }

  ${mq.between(mq.devices.tablet, mq.devices.tabletL)} {
    grid-template-columns: 220px repeat(4, minmax(0, 1fr));
  }

  ${mq.from(mq.devices.tabletL)} {
    grid-template-columns: 300px repeat(4, minmax(0, 1fr));
  }
`

const PricingInfo = styled.div`
  grid-row-start: 2;
  border-radius: 4px;
  overflow: hidden;

  ${mq.to(mq.devices.tablet)} {
    display: none;
  }
`

const Spacer = styled.div`
  grid-row-start: 1;
`

const PricingInfoRow = styled.div<{ centered?: boolean; empty?: boolean }>`
  min-height: 42px;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3625;

  ${mq.between(mq.devices.tablet, mq.devices.tabletL)} {
    font-size: 14px;
    padding: 8px;
    min-height: 38px;
  }

  &:nth-child(odd) {
    background-color: ${colors.white};
  }

  &:nth-child(even) {
    background-color: ${colors.veryLightGray};
  }

  ${(props) =>
    props.empty &&
    `
    ${mq.to(mq.devices.tablet)} {
      display: none;
    }
  `}

  ${(props) =>
    props.centered &&
    `
    position: relative;
    text-align: center;
  `}
`

const ShowOnMobile = styled.span`
  ${mq.from(mq.devices.tablet)} {
    display: none;
  }
`

const PricingPlan = styled.div`
  border-radius: 0 0 4px 4px;
  overflow: hidden;

  ${mq.to(mq.devices.tablet)} {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
`

const Featured = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  padding: 5px 1em;
  background-color: ${colors.ctaOrangeHover};
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-radius: 4px 4px 0 0;
`

const PricingHeader = styled.header<{ featured?: boolean }>`
  border-radius: 4px 4px 0 0;
  padding: 30px 15px;
  background-color: ${colors.purpleTransparent};
  color: ${colors.white};
  grid-row-start: 1;
  text-align: center;

  ${mq.to(mq.devices.tabletL)} {
    font-size: 14px;
  }

  ${(props) =>
    props.featured &&
    `
    position: relative;
    border-radius: 0;
    background-color: ${colors.darkOrange};
  `}

  h4 {
    margin: 0 0 6px;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.375;

    ${mq.from(mq.devices.tabletL)} {
      font-size: 24px;
    }
  }

  span {
    opacity: 0.5;
  }

  a {
    text-decoration: underline;
    color: ${colors.darkOrange};
    font-weight: 600;

    &:hover {
      color: ${colors.ctaOrange};
    }
  }
`

const CheckmarkIcon = styled(InlineSVG)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: ${colors.darkOrange};

  ${mq.to(mq.devices.tablet)} {
    position: static;
    margin-right: 0.5em;
    transform: none;
  }
`

const PricingTable = () => {
  const pricingInfoRows = data.rows.map((row, idx) => (
    <PricingInfoRow key={`pir-${idx}`}>{row}</PricingInfoRow>
  ))

  const planColumns = data.plans.map((plan, planIndex) => {
    const checkboxesUntilRow = plan.hasEveryFeatureBefore
      ? data.rows.indexOf(plan.hasEveryFeatureBefore)
      : data.rows.length

    const customValuesFor = Object.keys(plan.customFeatureValues)

    const rows = data.rows.map((row, rowIndex) => {
      let label: React.ReactNode =
        rowIndex < checkboxesUntilRow ? (
          <>
            <CheckmarkIcon icon="checkmark" />
            <ShowOnMobile> {row} </ShowOnMobile>
          </>
        ) : null

      if (customValuesFor.indexOf(row) !== -1) {
        label = (
          <>
            <ShowOnMobile>
              <CheckmarkIcon icon="checkmark" />
            </ShowOnMobile>
            {plan.customFeatureValues[row as 'Deals']}
            <ShowOnMobile> {row} </ShowOnMobile>
          </>
        )
      }

      return (
        <PricingInfoRow key={rowIndex} centered empty={!label}>
          {label}
        </PricingInfoRow>
      )
    })

    return (
      <React.Fragment key={planIndex}>
        <PricingHeader featured={plan.featured ? true : false}>
          {plan.featured ? <Featured>{plan.featured}</Featured> : null}
          <h4>{plan.title}</h4>
          {plan.subtitle}
        </PricingHeader>
        <PricingPlan>{rows}</PricingPlan>
      </React.Fragment>
    )
  })

  return (
    <Section>
      <Container>
        <Wrap>
          <Spacer />
          <PricingInfo>{pricingInfoRows}</PricingInfo>
          {planColumns}
        </Wrap>
      </Container>
    </Section>
  )
}

export default PricingTable
