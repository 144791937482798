import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from 'styled/colors'

const SButton = styled.button<{
  fullWidth?: boolean
  color?: PropsType['color']
}>`
  display: inline-block;
  padding: 0.7em 2em;
  border: 0;
  border-radius: 0.25em;
  font-weight: normal;
  font-size: 14px;
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  color: ${colors.white};
  background-color: ${colors.darkOrange};

  &:hover {
    background-color: ${colors.ctaOrangeHover};
  }

  ${(props) =>
    props.fullWidth &&
    `
    width: 100%;
  `}

  ${(props) =>
    props.color === 'aqua' &&
    `
    background-color: ${colors.ctaAqua};

    &:hover {
      background-color: ${colors.ctaAquaHover};
    }
  `}

  ${(props) =>
    props.color === 'transparent' &&
    `
    background-color: transparent;

    &:hover {
      background-color: ${colors.ctaAquaHover};
    }
  `}
`

export type PropsType = {
  type?: 'button' | 'submit' | 'reset'
  link?: {
    type: 'internal' | 'external'
    href: string
  }
  color?: 'orange' | 'aqua' | 'transparent'
  fullWidth?: boolean
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  children: React.ReactNode
}

const Button = ({
  onClick,
  link,
  children,
  className,
  fullWidth,
  type,
  color,
}: PropsType) => {
  const props = {
    onClick,
    className,
    type,
    fullWidth,
    color,
  }

  if (link) {
    const { type, href } = link
    if (type === 'internal') {
      return (
        <SButton as={Link} to={href} {...props}>
          {children}
        </SButton>
      )
    }

    if (type === 'external') {
      return (
        <SButton as="a" href={href} target="_blank" {...props}>
          {children}
        </SButton>
      )
    }
  }

  return <SButton {...props}>{children}</SButton>
}

export default Button
