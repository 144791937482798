const features = [
  {
    icon: 'feature-deal.svg',
    title: 'Deal creation and management',
    subtitle:
      'We make set-up easy. Use our pre-built and flexible workflow based on industry best practices with preset data fields for all major asset types.',
  },
  {
    icon: 'feature-relationship.svg',
    title: 'Relationship management',
    subtitle:
      'Keep a history of all your key relationships. Switchboard lets you automatically track all activities with your contacts, both internal and external, simply by collaborating on our network.',
  },
  {
    icon: 'feature-team.svg',
    title: 'Team and access management',
    subtitle:
      'No need to consult with an IT department to set things up. Our intuitive, role based permissioning system lets you easily control who has access to information.',
  },
  {
    icon: 'feature-deal.svg',
    title: 'Item tracking',
    subtitle:
      'Want to track conversations, documents, due dates and the status activities across your deals? Our items give you the flexibility to organize and manage your work however you see fit.',
  },
  {
    icon: 'feature-documents.svg',
    title: 'Document management',
    subtitle:
      'Organizing documents can be a bit of a chore. Our deal structures create an easily searchable indexed library of all your documents.',
  },
  {
    icon: 'feature-api.svg',
    title: 'Private API',
    subtitle:
      "Need to get your data and documents into your data warehouse or corporate document management solution? Don't worry, we've got you covered. Our API can push all your deal data, documents, and metadata to wherever you need it to go.",
  },
]

export default features
