import styled from 'styled-components'
import { device, from } from 'styledComponents/MediaQueries'
import { Link } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import Button from 'components/Button'
import { ReactElement } from 'react'

const Wrap = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  margin: 4em auto 2em;
  align-items: center;
  text-align: left;
`
const OuterWrap = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  height: 100;
`

const Title = styled.h1`
  margin-bottom: 1.5em;
  padding: 0 1em;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;

  ${from(device.ipadL)} {
    font-size: 40px;
  }

  small {
    display: block;
    letter-spacing: 0.75px;
    margin-top: 0.3em;
    font-size: 20px;
    font-weight: 400;

    ${from(device.ipadL)} {
      font-size: 30px;
    }
  }
`

const StyledLink = styled(Link)`
  && {
    text-decoration: none;
  }
`

type PropsType = {
  title: string
  content: ReactElement
}
const TextWrapper = ({ title, content }: PropsType) => {
  return (
    <DocumentTitle title="Switchboard | Privacy Policy">
      <OuterWrap>
        <Wrap>
          <Title>{title}</Title>
          {content}

          <div>
            <StyledLink to={`/`}>
              <Button fullWidth>Home</Button>
            </StyledLink>
          </div>
        </Wrap>
      </OuterWrap>
    </DocumentTitle>
  )
}

export default TextWrapper
