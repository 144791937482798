import Container from 'layout/Container'
import styled from 'styled-components'
import colors from 'styled/colors'
import mq from 'styled/mediaQuery'
import { TitleMedium, TitleSmall, Text } from 'components/Typography'

const Wrap = styled.div`
  color: ${colors.white};
`

const RowWrap = styled.div``

const Row = styled.div`
  margin-left: -1.5625rem;
  margin-right: -1.5625rem;

  ${mq.from(mq.devices.desktopMd)} {
    margin-left: -50px;
    margin-right: -50px;
  }
`

const Column = styled.div`
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;

  ${mq.from(mq.devices.desktopMd)} {
    padding-left: 50px;
    padding-right: 50px;
  }
`

const SContainer = styled(Container)`
  overflow: hidden;

  & + & {
    margin-top: 1.5625rem;
  }

  ${mq.from(mq.devices.tablet)} {
    & + & {
      margin-top: 3.125rem;
    }
  }

  ${mq.from(mq.devices.tablet)} {
    &:nth-child(even) ${Column}:first-child {
      order: 2;
    }
  }
`

const ColumnPicture = styled(Column)``

const ColumnText = styled(Column)``

const Title = styled(TitleMedium)`
  margin: 1.5625rem 0 5px;
`

const Subtitle = styled(TitleSmall)`
  margin: 5px 0 0;
  font-size: 20px;

  ${mq.from(mq.devices.tablet)} {
    font-size: 24px;
  }
`

const StyledText = styled(Text)`
  margin-top: 1.5em;
`

type ImageTextType = {
  rows: {
    image: string
    title: string
    subtitle?: string
    text?: string
  }[]
}

const ImageText = ({ rows }: ImageTextType) => {
  const nodes = rows.map((row, idx) => (
    <SContainer key={idx}>
      <Row className="row">
        <ColumnPicture className="col-xs-12 col-sm-6">
          <img src={row.image} alt={row.title} />
        </ColumnPicture>
        <ColumnText className="col-xs-12 col-sm-6">
          {row.title && <Title>{row.title}</Title>}
          {row.subtitle && <Subtitle>{row.subtitle}</Subtitle>}
          {row.text && <StyledText>{row.text}</StyledText>}
        </ColumnText>
      </Row>
    </SContainer>
  ))

  return (
    <Wrap>
      <RowWrap>{nodes}</RowWrap>
    </Wrap>
  )
}

export default ImageText
