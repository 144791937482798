const devices = {
  mobileS: 420,
  mobile: 480,
  mobileL: 540,
  tabletS: 648,
  tablet: 768,
  tabletL: 980,
  ipad: 1024,
  desktop: 1100,
  desktopMd: 1280,
  desktopLg: 1450,
}

const mediaQuery = {
  devices: devices,
  from: (b: number) => `@media (min-width: ${b}px)`,
  to: (b: number) => `@media (max-width: ${b - 1}px)`,
  between: (f: number, t: number) =>
    `@media (min-width: ${f}px) and (max-width: ${t - 1}px)`,
}

export default mediaQuery
