import React from 'react'
import TextWrapper from 'components/LegalPages'

const Content = () => (
  <div>
    <p>
      <span>
        Switchboard Exchange Inc (&quot;us&quot;, &quot;we&quot;, or
        &quot;our&quot;) operates the app.switchboard.exchange website
        (hereinafter referred to as the &quot;Service&quot;).
      </span>
    </p>
    <p>
      <span>
        This page informs you of our policies regarding the collection, use and
        disclosure of personal data when you use our Service and the choices you
        have associated with that data.
      </span>
    </p>
    <p>
      <span>
        We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy. Unless otherwise defined in this Privacy
        Policy, the terms used in this Privacy Policy have the same meanings as
        in our Terms and Conditions, accessible from app.switchboard.exchange
      </span>
    </p>
    <h2>
      <span>Definitions</span>
    </h2>
    <p>
      <span>Service</span>
    </p>
    <p>
      <span>
        Service is the app.switchboard.exchange website operated by Switchboard
        Exchange Inc
      </span>
    </p>
    <p>
      <span>Personal Data</span>
    </p>
    <p>
      <span>
        Personal Data means data about a living individual who can be identified
        from those data (or from those and other information either in our
        possession or likely to come into our possession).
      </span>
    </p>
    <p>
      <span>Usage Data</span>
    </p>
    <p>
      <span>
        Usage Data is data collected automatically either generated by the use
        of the Service or from the Service infrastructure itself (for example,
        the duration of a page visit).
      </span>
    </p>
    <p>
      <span>Cookies</span>
    </p>
    <p>
      <span>
        Cookies are small files stored on your device (computer or mobile
        device).
      </span>
    </p>
    <h2>
      <span>Information Collection and Use</span>
    </h2>
    <p>
      <span>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </span>
    </p>
    <h3>
      <span>Types of Data Collected</span>
    </h3>
    <h4>
      <span>Personal Data</span>
    </h4>
    <p>
      <span>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you (&quot;Personal Data&quot;). Personally identifiable
        information may include, but is not limited to:
      </span>
    </p>
    <p>
      <span>Email address</span>
    </p>
    <p>
      <span>First name and last name</span>
    </p>
    <p>
      <span>Address, State, Province, ZIP/Postal code, City</span>
    </p>
    <p>
      <span>Cookies and Usage Data</span>
    </p>
    <p>
      <span>
        We may use your Personal Data to contact you with newsletters, marketing
        or promotional materials and other information that may be of interest
        to you. You may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or the
        instructions provided in any email we send.
      </span>
    </p>
    <h4>
      <span>Usage Data</span>
    </h4>
    <p>
      <span>
        We may also collect information on how the Service is accessed and used
        (&quot;Usage Data&quot;). This Usage Data may include information such
        as your computer&#39;s Internet Protocol address (e.g. IP address),
        browser type, browser version, the pages of our Service that you visit,
        the time and date of your visit, the time spent on those pages, unique
        device identifiers and other diagnostic data.
      </span>
    </p>
    <h4>
      <span>Tracking Cookies Data</span>
    </h4>
    <p>
      <span>
        We use cookies and similar tracking technologies to track the activity
        on our Service and we hold certain information.
      </span>
    </p>
    <p>
      <span>
        Cookies are files with a small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Other tracking technologies are also
        used such as beacons, tags and scripts to collect and track information
        and to improve and analyse our Service.
      </span>
    </p>
    <p>
      <span>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </span>
    </p>
    <p>
      <span>Examples of Cookies we use:</span>
    </p>
    <p>
      <span>Session Cookies.</span>
      <span>&nbsp;We use Session Cookies to operate our Service.</span>
    </p>
    <p>
      <span>Preference Cookies.</span>
      <span>
        &nbsp;We use Preference Cookies to remember your preferences and various
        settings.
      </span>
    </p>
    <p>
      <span>Security Cookies.</span>
      <span>&nbsp;We use Security Cookies for security purposes.</span>
    </p>
    <h2>
      <span>Use of Data</span>
    </h2>
    <p>
      <span>
        Switchboard Exchange Inc uses the collected data for various purposes:
      </span>
    </p>
    <p>
      <span>To provide and maintain our Service</span>
    </p>
    <p>
      <span>To notify you about changes to our Service</span>
    </p>
    <p>
      <span>
        To allow you to participate in interactive features of our Service when
        you choose to do so
      </span>
    </p>
    <p>
      <span>To provide customer support</span>
    </p>
    <p>
      <span>
        To gather analysis or valuable information so that we can improve our
        Service
      </span>
    </p>
    <p>
      <span>To monitor the usage of our Service</span>
    </p>
    <p>
      <span>To detect, prevent and address technical issues</span>
    </p>
    <p>
      <span>
        To provide you with news, special offers and general information about
        other goods, services and events which we offer that are similar to
        those that you have already purchased or enquired about unless you have
        opted not to receive such information
      </span>
    </p>
    <h2>
      <span>Transfer of Data</span>
    </h2>
    <p>
      <span>
        Your information, including Personal Data, may be transferred to - and
        maintained on - computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction.
      </span>
    </p>
    <p>
      <span>
        If you are located outside Canada and choose to provide information to
        us, please note that we transfer the data, including Personal Data, to
        Canada and process it there.
      </span>
    </p>
    <p>
      <span>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </span>
    </p>
    <p>
      <span>
        Switchboard Exchange Inc will take all the steps reasonably necessary to
        ensure that your data is treated securely and in accordance with this
        Privacy Policy and no transfer of your Personal Data will take place to
        an organisation or a country unless there are adequate controls in place
        including the security of your data and other personal information.
      </span>
    </p>
    <h2>
      <span>Disclosure of Data</span>
    </h2>
    <h3>
      <span>Business Transaction</span>
    </h3>
    <p>
      <span>
        If Switchboard Exchange Inc is involved in a merger, acquisition or
        asset sale, your Personal Data may be transferred. We will provide
        notice before your Personal Data is transferred and becomes subject to a
        different Privacy Policy.
      </span>
    </p>
    <h3>
      <span>Disclosure for Law Enforcement</span>
    </h3>
    <p>
      <span>
        Under certain circumstances, Switchboard Exchange Inc may be required to
        disclose your Personal Data if required to do so by law or in response
        to valid requests by public authorities (e.g. a court or a government
        agency).
      </span>
    </p>
    <h3>
      <span>Legal Requirements</span>
    </h3>
    <p>
      <span>
        Switchboard Exchange Inc may disclose your Personal Data in the good
        faith belief that such action is necessary to:
      </span>
    </p>
    <p>
      <span>To comply with a legal obligation</span>
    </p>
    <p>
      <span>
        To protect and defend the rights or property of Switchboard Exchange Inc
      </span>
    </p>
    <p>
      <span>
        To prevent or investigate possible wrongdoing in connection with the
        Service
      </span>
    </p>
    <p>
      <span>
        To protect the personal safety of users of the Service or the public
      </span>
    </p>
    <p>
      <span>To protect against legal liability</span>
    </p>
    <h2>
      <span>Security of Data</span>
    </h2>
    <p>
      <span>
        The security of your data is important to us but remember that no method
        of transmission over the Internet or method of electronic storage is
        100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.
      </span>
    </p>
    <h2>
      <span>
        Our Policy on &quot;Do Not Track&quot; Signals under the California
        Online Protection Act (CalOPPA)
      </span>
    </h2>
    <p>
      <span>
        We do not support Do Not Track (&quot;DNT&quot;). Do Not Track is a
        preference you can set in your web browser to inform websites that you
        do not want to be tracked.
      </span>
    </p>
    <p>
      <span>
        You can enable or disable Do Not Track by visiting the Preferences or
        Settings page of your web browser.
      </span>
    </p>
    <h2>
      <span>Service Providers</span>
    </h2>
    <p>
      <span>
        We may employ third party companies and individuals to facilitate our
        Service (&quot;Service Providers&quot;), provide the Service on our
        behalf, perform Service-related services or assist us in analysing how
        our Service is used.
      </span>
    </p>
    <p>
      <span>
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </span>
    </p>
    <h3>
      <span>Analytics</span>
    </h3>
    <p>
      <span>
        We may use third-party Service Providers to monitor and analyse the use
        of our Service.
      </span>
    </p>
    <p>
      <span>Google Analytics</span>
    </p>
    <p>
      <span>
        Google Analytics is a web analytics service offered by Google that
        tracks and reports website traffic. Google uses the data collected to
        track and monitor the use of our Service. This data is shared with other
        Google services. Google may use the collected data to contextualise and
        personalise the ads of its own advertising network.
      </span>
    </p>
    <p>
      <span>
        You can opt-out of having made your activity on the Service available to
        Google Analytics by installing the Google Analytics opt-out browser
        add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
        analytics.js and dc.js) from sharing information with Google Analytics
        about visits activity.
      </span>
    </p>
    <p>
      <span>
        For more information on the privacy practices of Google, please visit
        the Google Privacy Terms web page:
      </span>
      <span>
        <a href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1636782132849000&amp;usg=AOvVaw3GadFZFk6-BA53_aVLOiYQ">
          &nbsp;
        </a>
      </span>
      <span>
        <a href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1636782132849000&amp;usg=AOvVaw3GadFZFk6-BA53_aVLOiYQ">
          https://policies.google.com/privacy?hl=en
        </a>
      </span>
    </p>
    <h2>
      <span>Links to Other Sites</span>
    </h2>
    <p>
      <span>
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party&#39;s site. We strongly advise you to review the Privacy Policy of
        every site you visit.
      </span>
    </p>
    <p>
      <span>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </span>
    </p>
    <h2>
      <span>Children&#39;s Privacy</span>
    </h2>
    <p>
      <span>
        Our Service does not address anyone under the age of 18
        (&quot;Children&quot;).
      </span>
    </p>
    <p>
      <span>
        We do not knowingly collect personally identifiable information from
        anyone under the age of 18. If you are a parent or guardian and you are
        aware that your Child has provided us with Personal Data, please contact
        us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
      </span>
    </p>
    <h2>
      <span>Changes to This Privacy Policy</span>
    </h2>
    <p>
      <span>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </span>
    </p>
    <p>
      <span>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the &quot;effective
        date&quot; at the top of this Privacy Policy.
      </span>
    </p>
    <p>
      <span>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </span>
    </p>
    <h2>
      <span>Contact Us</span>
    </h2>
    <p>
      If you have any questions about this Privacy Policy Terms, please
      <a href={'mailto:info@switchboard.exchange'}> contact us.</a>
    </p>
  </div>
)
const PrivacyPolicy = () => (
  <TextWrapper title={'Privacy Policy'} content={<Content />} />
)

export default PrivacyPolicy
