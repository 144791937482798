import styled from 'styled-components'
import Section from 'layout/Section'
import Container from 'layout/Container'
import { TitleRegular, Text } from 'components/Typography'
import mq from 'styled/mediaQuery'
import colors from 'styled/colors'
import data from './data'

const Feature = styled.div`
  display: flex;

  ${mq.to(mq.devices.mobileL)} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  & + & {
    margin-top: 50px;

    ${mq.from(mq.devices.mobileL)} {
      margin-top: 30px;
    }
  }
`

const FeatureImage = styled.div`
  width: 150px;
  flex-shrink: 0;

  ${mq.to(mq.devices.mobileL)} {
    margin-bottom: 1em;
  }

  ${mq.from(mq.devices.mobileL)} {
    margin-right: 1.5em;
  }

  ${mq.from(mq.devices.tablet)} {
    margin-right: 3.125em;
    width: 190px;
  }

  img {
    background-color: ${colors.purpleTransparent};
    border-radius: 10px;
  }
`

const FeatureText = styled.div`
  flex-grow: 1;
`

const StyledTitle = styled(TitleRegular)`
  margin: 0 0 0.6em;
`

const StyledText = styled(Text)`
  margin: 0;
`

const FeatureList = () => {
  const features = data.map((feature, idx) => (
    <Feature key={idx}>
      <FeatureImage>
        <img src={`images/features/${feature.icon}`} alt={feature.title} />
      </FeatureImage>
      <FeatureText>
        <StyledTitle>{feature.title}</StyledTitle>
        <StyledText>{feature.subtitle}</StyledText>
      </FeatureText>
    </Feature>
  ))

  return (
    <Section>
      <Container narrow>{features}</Container>
    </Section>
  )
}

export default FeatureList
