import styled from 'styled-components'
import mq from 'styled/mediaQuery'

const Wrap = styled.div`
  & + & {
    margin-top: 20px;
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  line-height: 1.375;
`

const Input = styled.input`
  display: block;
  width: 100%;
  border: 0;
  padding: 0.75em 1em;
  border-radius: 0.3em;

  ${mq.from(mq.devices.tablet)} {
    border-radius: 0.625em;
  }
`

const Textarea = styled(Input)`
  resize: none;
  min-height: 175px;
`

export type PropsType = {
  type: 'text' | 'email' | 'password' | 'textarea'
  value: string
  name: string
  placeholder?: string
  label?: string
  required?: boolean
  onChange?: React.ChangeEventHandler
}

const FormInput = ({
  name,
  type,
  placeholder,
  value,
  label,
  required,
  onChange,
}: PropsType) => (
  <Wrap>
    {label && <Label htmlFor={`field-${name}`}>{label}</Label>}
    {type === 'textarea' ? (
      <Textarea
        as="textarea"
        id={`field-${name}`}
        name={name}
        value={value}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
      />
    ) : (
      <Input
        id={`field-${name}`}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
      />
    )}
  </Wrap>
)

export default FormInput
