import Section from 'layout/Section'
import Footer from 'layout/Footer'
import ImageText from 'components/ImageText'
import CTAForm from 'components/CTAForm/wrapped'
import Hero from 'components/Hero'
import OrangeIntro from './OrangeIntro'
import Button from 'components/Button'

const Home = () => (
  <>
    <Hero
      withIllustration
      title="Connecting the real estate industry under one platform"
      subtitle="From the small stuff to the big picture, Switchboard allows investors across the spectrum to stay organized and connected."
      cta={
        <Button
          link={{
            type: 'external',
            href: 'https://auth.switchboard.exchange/u/signup?state=hKFo2SBNU2p6a29IZnhOVk1yVk5zY3N4anlCa2FfX3BtVnRPOaFur3VuaXZlcnNhbC1sb2dpbqN0aWTZIFFUWUNmNDVzbERqNjdtd19QREwxUl9ZZFZSN01HSFU5o2NpZNkga1dZRWtUeWh4Ymg2dXhCZTZ3QmtKUGhCVTRFN1N0RHA',
          }}
        >
          Get started
        </Button>
      }
    />

    <OrangeIntro
      title="A new way of working together"
      subtitle="Switchboard's intuitive features give any team the ability to get set up quickly and make every deal a success. Reach new levels of productivity, see where tasks stand in a flash, identify next steps, and quickly take action to stay on track!"
      cards={[
        {
          image: '/images/svg-collaborate.svg',
          title: 'Collaborate',
          subtitle:
            "Connect with your entire network on one platform and get real-time insight into the state of your team's work.",
        },
        {
          image: '/images/svg-optimize.svg',
          title: 'Optimize',
          subtitle:
            'Get a holistic view of your deals, communications and documents. Keep all your work on track using one intuitive platform.',
        },
        {
          image: '/images/svg-analyze.svg',
          title: 'Analyze',
          subtitle:
            'Make data-driven decisions and deliver value to your team faster, with real-time insights at your fingertips.',
        },
      ]}
    />

    <Section title="Hit your goals, without the scramble">
      <ImageText
        rows={[
          {
            image: '/images/img-organized.png',
            title: 'Stay organized',
            text: "Switchboard lets you keep track of all your deals, tasks and communications in one shared space. No more searching through emails to find documents, wondering if you have the latest version! See what's important and where things are getting stuck in one glance.",
          },
          {
            image: '/images/img-relationships.png',
            title: 'Keep track of your relationships',
            text: "Connecting and working with people on Switchboard lets you automatically track your relationships and deal history. No more mindless work doing data entry into your CRM. From counterparties, to partners and team members, get an accurate view of what you've worked on and who you've done it with.",
          },
          {
            image: '/images/img-permissions.png',
            title: 'Easily manage your teams and permissions',
            text: 'Everything in one place sounds fun, but you need to manage who sees what. Switchboard makes it easy to split work between teams, and manage what information is accessible to partners and third party advisors.',
          },
        ]}
      />
    </Section>

    <CTAForm />

    <Footer />
  </>
)

export default Home
