import Footer from 'layout/Footer'
import CTAForm from 'components/CTAForm/wrapped'
import Hero from 'components/Hero'
import FeatureList from './FeatureList'

const About = () => (
  <>
    <Hero
      title="Switchboard Features"
      subtitle="Discover the features you need to run your business."
    />

    <FeatureList />

    <CTAForm />

    <Footer />
  </>
)

export default About
