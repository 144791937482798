import Footer from 'layout/Footer'
import CTAForm from 'components/CTAForm/wrapped'
import Hero from 'components/Hero'
import PricingTable from './PricingTable'

const Pricing = () => (
  <>
    <Hero
      title="Pricing"
      subtitle="Switchboard's standard feature set will always remain free. Premium and pro versions coming soon; please call us for access to our enterprise platform."
    />

    <PricingTable />

    <CTAForm />

    <Footer />
  </>
)

export default Pricing
