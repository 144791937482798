import styled from 'styled-components'
import mq from 'styled/mediaQuery'
import { Text } from 'components/Typography'
import colors from 'styled/colors'
import data from './data'

const Grid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;

  ${mq.from(mq.devices.tabletS)} {
    grid-template-columns: 1fr 1fr;
  }

  ${mq.from(mq.devices.desktop)} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const GridBlock = styled.div`
  padding: 20px 20px 40px;
  background-color: ${colors.purpleTransparent};
  border-radius: 0.3em;

  ${mq.from(mq.devices.tablet)} {
    border-radius: 0.625em;
  }
`

const GridTitle = styled.h4`
  margin: 0 0 6px;
  font-size: 20px;

  ${mq.from(mq.devices.tablet)} {
    font-size: 26px;
  }
`

const GridSubtitle = styled.h5`
  margin: 0;
  font-size: 18px;
  opacity: 0.5;

  ${mq.from(mq.devices.tablet)} {
    font-size: 20px;
  }
`

const GridText = styled(Text)`
  ${mq.from(mq.devices.desktop)} {
    font-size: 18px;
  }
`

const TeamGrid = () => {
  const blocks = data.map((member, idx) => (
    <GridBlock key={idx}>
      <GridTitle>{member.name}</GridTitle>
      <GridSubtitle>{member.title}</GridSubtitle>
      <GridText>{member.bio}</GridText>
    </GridBlock>
  ))

  return <Grid>{blocks}</Grid>
}

export default TeamGrid
