import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../../routes'
import mq from 'styled/mediaQuery'
import colors from 'styled/colors'
import { scrollTo } from 'utils'

const Wrap = styled.div`
  margin-left: 20px;

  ${mq.to(mq.devices.tabletL)} {
    display: none;
  }

  ${mq.from(mq.devices.ipad)} {
    margin-left: 40px;
  }

  ${mq.from(mq.devices.desktopMd)} {
    margin-left: 80px;
  }
`

const Link = styled(NavLink)`
  display: inline-block;
  padding: 5px 8px;
  color: currentColor;
  text-decoration: none;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover:not(.active) {
    opacity: 0.8;
  }

  & + & {
    margin-left: 20px;

    ${mq.from(mq.devices.ipad)} {
      margin-left: 30px;
    }

    ${mq.from(mq.devices.desktopMd)} {
      margin-left: 50px;
    }
  }

  &.active {
    border-bottom-color: ${colors.ctaOrange};
  }
`

const MainMenu = () => (
  <Wrap>
    <Link to={PATHS.FEATURES}>Features</Link>
    <Link to={PATHS.PRICING}>Pricing</Link>
    <Link to={PATHS.ABOUT}>About</Link>
    <Link as="span" onClick={() => scrollTo('contact-form')}>
      Contact
    </Link>
  </Wrap>
)

export default MainMenu
