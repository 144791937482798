const data = [
  {
    name: 'Matthew Etco',
    title: 'CEO',
    bio: "15 years experience in FinTech having worked for three of Canada's major financial institutions. Expert in business analysis, process optimization & CRM/ERP implementation.",
  },
  {
    name: 'Alexis Dray',
    title: 'CTO',
    bio: 'Solution and software architect specialist with more than a decade of experience in designing and building web and mobile applications.',
  },
  {
    name: 'Marie-Claude Paquette',
    title: 'COO',
    bio: 'Trained as an Engineer from Polytechnique, Marie-Claude specializes in applying lean process and supply chain management to IT focused startups and enterprise.',
  },
  {
    name: 'Kris Wojtecki',
    title: 'Advisor',
    bio: '20 Years of industry experience, formerly Managing Director at PSP Investments, a Canadian Pension Fund based in Montreal, Canada. Kris has a prominent career in helping to transform cities with dynamic development projects.',
  },
  {
    name: 'Quentin Nahum',
    title: 'Advisor',
    bio: 'CFA charterholder with over 15 years experience in the investment industry. Expert in portfolio management and implementation of investment management solutions.',
  },
]

export default data
