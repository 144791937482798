import React from 'react'
import styled from 'styled-components'
import Container from 'layout/Container'
import Button, { PropsType as ButtonPropsType } from 'components/Button'
import { TitleSmall } from 'components/Typography'
import colors from 'styled/colors'
import mq from 'styled/mediaQuery'

const Wrap = styled.div``

const Row = styled.div`
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
`

const Column = styled.div`
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.paleYellow};
`

const Image = styled.div`
  padding: 1.5em 1.5em 0;

  ${mq.from(mq.devices.tablet)} {
    padding: 2.5em 2.5em 0;
  }
`

const Text = styled.div`
  padding: 1.5em 1em;
  text-align: center;

  ${mq.from(mq.devices.tablet)} {
    padding: 2.5em 1.875em;
  }
`

const Title = styled(TitleSmall)`
  margin-top: 0;
  font-weight: 600;
`

const Subtitle = styled(TitleSmall)`
  &:not(:last-child) {
    margin-bottom: 0;
  }
`

const SButton = styled(Button)`
  margin-top: 40px;
`

export type CardPropType = {
  image: string
  title: string
  subtitle?: string
  button?: Pick<ButtonPropsType, 'link'> & { text: React.ReactNode }
}

export type PropsType = {
  cards: CardPropType[]
  className?: string
}

const CardGrid = ({ className, cards }: PropsType) => {
  const nodes = cards.map((card, idx) => (
    <Column key={idx} className="col-xs-12 col-sm-4">
      <Card>
        <Image>
          <img src={card.image} alt={card.title} />
        </Image>
        <Text>
          <Title>{card.title}</Title>
          <Subtitle>{card.subtitle}</Subtitle>
          {card.button && (
            <SButton link={card.button.link}>{card.button.text}</SButton>
          )}
        </Text>
      </Card>
    </Column>
  ))

  return (
    <Wrap className={className}>
      <Container>
        <Row className="row">{nodes}</Row>
      </Container>
    </Wrap>
  )
}

export default CardGrid
