import Footer from 'layout/Footer'
import Section from 'layout/Section'
import Container from 'layout/Container'
import CTAForm from 'components/CTAForm/wrapped'
import Hero from 'components/Hero'
import { TitleMedium, Text } from 'components/Typography'
import TeamGrid from './TeamGrid'
import styled from 'styled-components'
import colors from 'styled/colors'

const Link = styled.a`
  display: inline-block;
  text-decoration: none;
  border: 1px solid transparent;
  cursor: pointer;
  color: currentColor;
  transition: all 0.3s ease;
  border-bottom-color: ${colors.ctaOrange};

  &:hover {
    color: ${colors.ctaOrange};
    border-bottom-color: ${colors.white};
  }
`

const About = () => (
  <>
    <Hero title="About" />
    <Section>
      <Container>
        <TitleMedium>Our story</TitleMedium>
        <Text>
          Switchboard was founded by a group of colleagues in the investment
          community who saw a glaring, unaddressed problem shared by anyone who
          has ever gone through the process of managing a deal:
          <Text>
            <b>
              All deal management platforms are designed as an afterthought, and
              by people far removed from the day to day ins and out of deal
              management.
            </b>
          </Text>
          Existing platforms are slow, clunky and typically end up adding
          additional busy work to what is an already challenging process. We
          wanted a solution that was integrated in how we work, providing
          streamlined communication as well as document and activity management,
          all in one convenient portal.
        </Text>

        <Text>
          The majority of investments are done in conjunction with outside
          parties, each working in their own systems and with no common area for
          real collaboration. Isolation between teams lends itself to effort
          duplication, data segregation, disparate communications and an overall
          lack of critical real-time information and feedback. In short, a whole
          lot of avoidable headaches which have a very tangible impact on deal
          success metrics. We call this outmoded way of deal management,
          <i> &quot;managing by broken telephone...&quot;</i> and Switchboard is
          here to fix the network.
        </Text>

        <Text>
          Switchboard solves the problem of siloed parties by creating a central
          nexus of collaboration, allowing everyone involved in the deal to work
          together easily and seamlessly. It&apos;s built for enterprise but
          remains accessible and user friendly to all investors, institutional
          and non-institutional alike. In fact, a core value of Switchboard is
          that powerful and complex business systems shouldn&apos;t be
          inherently difficult to use. Switchboard is powered by a modern,
          intuitive user interface which makes it a breeze to get started and
          familiar with. There&apos;s nothing to install, and access is
          completely free, so trying out Switchboard has zero risk.
        </Text>
        <Text>
          <Link href="https://app.switchboard.exchange">
            Connect and collaborate on Switchboard now
          </Link>
          ,&nbsp; and start making deal-making easy!
        </Text>
      </Container>
    </Section>

    <Section>
      <Container>
        <TitleMedium>Our team</TitleMedium>
        <TeamGrid />
      </Container>
    </Section>

    <CTAForm />
    <Footer />
  </>
)

export default About
