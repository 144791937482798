import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import Input, { PropsType as InputType } from 'components/FormInput'
import Button from 'components/Button'
import { TitleMedium, TitleMammoth } from 'components/Typography'
import Container from 'layout/Container'
import mq from 'styled/mediaQuery'
import colors from 'styled/colors'

const FORM_API_KEY = process.env.REACT_APP_FORM_API_KEY

const Wrap = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;

  ${mq.from(mq.devices.desktopMd)} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const Row = styled.div`
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
`

const Column = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
`

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  background-color: ${colors.dark};
  z-index: 5;

  @keyframes spinneroni {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &::after {
    content: ' ';
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border-radius: 50%;
    border: 4px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: spinneroni 1.2s linear infinite;
  }
`

const Footer = styled.div<{ loading?: string }>`
  position: relative;
  margin-top: 40px;

  button {
    display: block;
    width: auto;
    margin-left: auto;
  }

  ${(props) =>
    props.loading &&
    `
    ${Loader} {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  `}
`

const TinyTitle = styled(TitleMedium)`
  margin-top: 0;
`

const CTATitle = styled(TitleMammoth)`
  ${mq.from(mq.devices.desktop)} {
    font-size: 75px;
  }

  ${mq.to(mq.devices.tablet)} {
    margin: 20px 0;
  }
`

const FormPostMessage = styled.p<{ success?: boolean; error?: boolean }>`
  margin-top: 2em;
  margin-bottom: 0;

  ${(props) =>
    props.success &&
    `
    color: ${colors.ctaGreen};
  `}

  ${(props) =>
    props.error &&
    `
    color: ${colors.ctaRed};
  `}
`

type CTAFormType = {
  tinyTitle?: string
  title?: string
  form: {
    fields: Omit<InputType, 'value'>[]
    subscribe: string
    successMessage: string
    errorMessage: string
  }
}

type StateType = {
  [index: string]: string
}

const CTAForm = ({ tinyTitle, title, form }: CTAFormType) => {
  const [state, setState] = useState<StateType>({
    error: '',
    subject: '',
  })

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.getAttribute('name') as string]: e.target.value,
    })
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const { loading } = state
    if (loading) {
      return false
    } else {
      setState({
        ...state,
        loading: 'true',
      })
    }

    const formData = {
      name: state.name,
      email: state.email,
      subject: state.subject,
      emailBody: state.message,
    }

    console.log('submitting form:', formData)

    axios({
      method: 'post',
      url: 'https://f3kukxvq41.execute-api.us-east-1.amazonaws.com/default/switchboardMarketingContactEmail',
      data: formData,
      headers: {
        'x-api-key': FORM_API_KEY as string,
      },
    })
      .then((response) => {
        // Reset fields, but keep the subject
        setState({
          subject: '',
          success: form.successMessage,
        })
      })
      .catch((error) => {
        console.log('form could not be submitted due to', error)
        setState({
          ...state,
          loading: '',
          error: form.errorMessage,
        })
      })
  }

  const inputs = form.fields.map((field, idx) => (
    <Input
      key={idx}
      value={state[field.name] || ''}
      type={field.type}
      name={field.name}
      placeholder={field.placeholder}
      label={field.label}
      required={field.required}
      onChange={onInputChange}
    />
  ))

  return (
    <Wrap>
      <Row className="row">
        <Column className="col-xs-12 col-sm-6">
          <TinyTitle>{tinyTitle}</TinyTitle>
          <CTATitle as="h2">{title}</CTATitle>
        </Column>
        <Column className="col-xs-12 col-sm-6">
          <form action="#" onSubmit={onSubmit}>
            {inputs}
            {state.success && (
              <FormPostMessage success>{state.success}</FormPostMessage>
            )}
            {state.error && (
              <FormPostMessage error>{state.error}</FormPostMessage>
            )}
            <Footer loading={state.loading}>
              <Button fullWidth type="submit">
                {form.subscribe}
              </Button>
              <Loader />
            </Footer>
          </form>
        </Column>
      </Row>
    </Wrap>
  )
}

export default CTAForm
