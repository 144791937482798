import styled from 'styled-components'
import mq from 'styled/mediaQuery'

export const TitleMammoth = styled.h1`
  margin: 30px 0;
  font-size: 40px;
  line-height: 1.14;

  ${mq.from(mq.devices.tablet)} {
    font-size: 52px;
  }

  ${mq.from(mq.devices.desktop)} {
    font-size: 70px;
  }
`

export const TitleBig = styled.h2`
  font-size: 28px;
  line-height: 1.2;

  ${mq.from(mq.devices.tablet)} {
    font-size: 38px;
  }

  ${mq.from(mq.devices.desktop)} {
    font-size: 50px;
  }
`

export const TitleMedium = styled.h3`
  margin: 20px auto;
  font-size: 28px;
  line-height: 1.35;

  ${mq.from(mq.devices.tablet)} {
    margin: 30px auto;
    font-size: 36px;
  }
`

export const TitleRegular = styled.h3`
  margin: 20px auto;
  font-size: 24px;
  line-height: 1.35;

  ${mq.from(mq.devices.tablet)} {
    margin: 30px auto;
    font-size: 30px;
  }
`

export const TitleSmall = styled.h4`
  margin: 20px auto;
  font-size: 18px;
  line-height: 1.35;

  ${mq.from(mq.devices.tablet)} {
    font-size: 20px;
  }
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.362;

  ${mq.from(mq.devices.tablet)} {
    font-size: 18px;
  }

  ${mq.from(mq.devices.desktop)} {
    font-size: 20px;
  }
`

export const a = styled.a`
   color:
`
