import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { PATHS } from '../../../routes'
import colors from 'styled/colors'
import { scrollTo } from 'utils'

const Wrap = styled.div<{ show: boolean; offset: number }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 1em;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  background-color: ${colors.dark};
  color: ${colors.white};
  z-index: 20;
  overflow: auto;

  ${(props) =>
    props.offset &&
    `
    top: ${props.offset}px;
  `}

  ${(props) =>
    props.show &&
    `
    opacity: 1;
    visibility: visible;
  `}
`

const StyledLink = styled(Link)`
  display: inline-block;
  margin-bottom: 34px;
  padding: 1rem;
  cursor: pointer;
  color: currentColor;
  text-decoration: none;
  font-size: 24px;

  @media (max-height: 610px) {
    margin-bottom: 14px;
  }

  @media (max-height: 500px) {
    margin-bottom: 0;
  }

  + a {
    padding: 1rem;
    font-size: 24px;

    span {
      width: 1em;
      height: 1em;
      font-size: 34px;
    }
  }
`

type PropsType = {
  menuWrapRef: React.RefObject<HTMLDivElement>
  toggleOffcanvas: () => void
  show: boolean
  logIn?: React.ReactNode
}

const MainMenu = ({ menuWrapRef, toggleOffcanvas, show, logIn }: PropsType) => {
  let offsetOffcanvasBy = 0
  if (menuWrapRef.current) {
    offsetOffcanvasBy = menuWrapRef.current.getBoundingClientRect().height
  }

  return (
    <Wrap offset={offsetOffcanvasBy} show={show}>
      <StyledLink to={PATHS.FEATURES} onClick={toggleOffcanvas}>
        Features
      </StyledLink>
      <StyledLink to={PATHS.PRICING} onClick={toggleOffcanvas}>
        Pricing
      </StyledLink>
      <StyledLink to={PATHS.ABOUT} onClick={toggleOffcanvas}>
        About
      </StyledLink>
      <StyledLink
        as="span"
        onClick={() => {
          toggleOffcanvas()
          scrollTo('contact-form')
        }}
      >
        Contact Us
      </StyledLink>
      {logIn}
    </Wrap>
  )
}

export default MainMenu
