const data = {
  rows: [
    'Deal metrics & pipeline',
    'Team management',
    'Document management',
    'Relationship management',
    'Item tracking',
    'Standard Processes',
    'Deals',
    'Relationship management',
    'Team presets',
    'Bid management',
    'Portfolios',
    'SSO',
    'Corporate accounts',
    'User administration',
    'API access',
  ],
  plans: [
    {
      featured: 'Most popular',
      title: 'Standard',
      subtitle: <span>Free</span>,
      hasEveryFeatureBefore: 'Team presets',
      customFeatureValues: {
        Deals: 'Unlimited (During Beta)',
      },
    },
    {
      title: 'Premium',
      subtitle: <span>Coming soon</span>,
      hasEveryFeatureBefore: 'Bid management',
      customFeatureValues: {
        Deals: 'Unlimited',
      },
    },
    {
      title: 'Pro',
      subtitle: <span>Coming soon</span>,
      hasEveryFeatureBefore: 'SSO',
      customFeatureValues: {
        Deals: 'Unlimited',
      },
    },
    {
      title: 'Custom',
      subtitle: <a href="tel:514-913-6288">Call</a>,
      customFeatureValues: {
        Deals: 'Unlimited',
      },
    },
  ],
}

export default data
