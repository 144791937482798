import { useEffect, useState, useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { throttle } from 'lodash'
import Container from 'layout/Container'
import Button from 'components/Button'
import InlineSVG from 'components/InlineSVG'
import MainMenu from './MainMenu'
import Offcanvas from './Offcanvas'
import colors from 'styled/colors'
import mq from 'styled/mediaQuery'

const Outer = styled.div`
  position: relative;
  z-index: 5;
`

const Spacer = styled.div``

const Wrap = styled.header<{ fixed: boolean; slideUp: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: ${colors.white};
  padding: 10px 0;
  transition: all 0.3s ease, box-shadow 0.3s ease;
  transition-delay: 0s, 0.3s;
  z-index: 10;

  ${(props) =>
    props.fixed &&
    `
    background-color: ${colors.dark};
    box-shadow: 0 0 1em -0.5em ${colors.black};
  `}

  ${(props) =>
    props.slideUp &&
    `
    transform: translateY(-100%);
  `}
`

const Inner = styled(Container)`
  display: flex;
  align-items: center;
`

const LogoLong = styled(Link)`
  width: 233px;
  color: currentColor;

  ${mq.to(mq.devices.tablet)} {
    display: none;
  }
`

const LogoShort = styled(Link)`
  width: 2.2em;
  color: currentColor;

  ${mq.from(mq.devices.tablet)} {
    display: none;
  }
`

const ButtonWrap = styled.div`
  margin-left: auto;

  > * {
    margin-left: 1rem;
  }
`

const ButtonLogIn = styled(Button)`
  padding-left: 1em;
  padding-right: 1em;

  &:hover {
    background-color: transparent;
    opacity: 0.8;
  }

  &:first-child {
    ${mq.to(mq.devices.mobileS)} {
      display: none;
    }
  }

  &:last-child {
    ${mq.from(mq.devices.mobileS)} {
      display: none;
    }
  }
`

const AccountIcon = styled(InlineSVG)`
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin: -9px 10px -3px 0;
`

const loginButton = (
  <ButtonLogIn
    color="transparent"
    link={{
      type: 'external',
      href: 'https://auth.switchboard.exchange/u/login?state=hKFo2SBNU2p6a29IZnhOVk1yVk5zY3N4anlCa2FfX3BtVnRPOaFur3VuaXZlcnNhbC1sb2dpbqN0aWTZIFFUWUNmNDVzbERqNjdtd19QREwxUl9ZZFZSN01HSFU5o2NpZNkga1dZRWtUeWh4Ymg2dXhCZTZ3QmtKUGhCVTRFN1N0RHA',
    }}
  >
    <AccountIcon autoSize icon="account" />
    Login
  </ButtonLogIn>
)

const OffcanvasToggle = styled(Button)<{ offcanvas?: boolean }>`
  position: relative;
  margin-right: -1em;
  padding: 0.6em 1em;
  vertical-align: middle;
  background-color: transparent;
  opacity: 1;

  ${mq.from(mq.devices.tabletL)} {
    display: none;
  }

  > span:last-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
  }

  > span {
    font-size: 1.35em;
    transition: all 0.3s ease;

    ${(props) =>
      props.offcanvas &&
      `
      &:first-child {
        opacity: 0;
        visibility: hidden;
      }

      &:last-child {
        opacity: 1;
        visibility: visible;
      }
    `}
  }

  &:hover {
    background-color: transparent;
    opacity: 0.8;
  }
`

const Header = () => {
  const [state, setState] = useState({
    slideUp: false,
    fixed: false,
    offcanvas: false,
  })

  const wrapRef = useRef<HTMLDivElement>(null)
  const spacerRef = useRef<HTMLDivElement>(null)
  const currentScroll = useRef(window.pageYOffset) // store current scroll

  // On component mount, set spacer height to account for fixed header
  useEffect(() => {
    if (wrapRef.current !== null) {
      const height = wrapRef.current.getBoundingClientRect().height
      if (spacerRef.current !== null) {
        spacerRef.current.style.height = `${height}px`
      }
    }
  }, [])

  const toggleOffcanvas = () => {
    setState({
      ...state,
      offcanvas: !state.offcanvas,
    })
  }

  const throttledScroll = throttle(
    useCallback(() => {
      // Fix Safari scrolling when it goes into negative numbers
      const windowScroll = Math.max(window.pageYOffset, 0)
      const hide = windowScroll > currentScroll.current

      if (windowScroll === 0) {
        setState({
          ...state,
          slideUp: false,
          fixed: false,
        })
      } else {
        if (hide !== state.slideUp) {
          setState({
            ...state,
            fixed: true,
            slideUp: hide,
          })
        }
      }
      currentScroll.current = windowScroll
    }, [state]),
    200,
  )

  // Slide header up/down when window scroll changes
  useEffect(() => {
    window.addEventListener('scroll', throttledScroll)

    // Cleanup on unmount
    return () => {
      window.removeEventListener('scroll', throttledScroll)
    }
  }, [throttledScroll])

  // Disable body scroll when offcanvas menu is triggered
  useEffect(() => {
    if (state.offcanvas) {
      window.document.body.style.overflow = 'hidden'
    } else {
      window.document.body.style.overflow = ''
    }
  }, [state.offcanvas])

  return (
    <Outer>
      <Spacer ref={spacerRef} />
      <Wrap fixed={state.fixed} slideUp={state.slideUp} ref={wrapRef}>
        <Inner>
          <LogoLong to="/">
            <InlineSVG autoSize icon="logo" />
          </LogoLong>
          <LogoShort to="/">
            <InlineSVG autoSize icon="logo-short" />
          </LogoShort>
          <MainMenu />
          <ButtonWrap>
            {loginButton}
            <Button
              link={{
                type: 'external',
                href: 'https://app.switchboard.exchange',
              }}
            >
              Sign up for free
            </Button>
            <OffcanvasToggle
              offcanvas={state.offcanvas}
              onClick={toggleOffcanvas}
            >
              <InlineSVG icon="hamburger" />
              <InlineSVG icon="close" />
            </OffcanvasToggle>
          </ButtonWrap>
        </Inner>
      </Wrap>
      <Offcanvas
        menuWrapRef={wrapRef}
        toggleOffcanvas={toggleOffcanvas}
        show={state.offcanvas}
        logIn={loginButton}
      />
    </Outer>
  )
}

export default Header
