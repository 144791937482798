import styled from 'styled-components'
import Section from 'layout/Section'
import colors from 'styled/colors'
import CTAForm from './'

const StyledSection = styled(Section)`
  margin-bottom: 0;
  background-color: ${colors.purple};
`

const WrappedForm = () => (
  <StyledSection id="contact-form">
    <CTAForm
      tinyTitle="Questions? Comments? Concerns?"
      title="We'd love to hear from you!"
      form={{
        fields: [
          {
            name: 'name',
            type: 'text',
            label: 'Name *',
            required: true,
          },
          {
            name: 'email',
            type: 'email',
            label: 'Email *',
            required: true,
          },
          {
            name: 'message',
            type: 'textarea',
            label: 'Message *',
            required: true,
          },
        ],
        errorMessage: 'Form could not be submitted, please try again later.',
        successMessage:
          'Thanks for contacting us. Someone will get in touch as soon as possible!',
        subscribe: 'Send',
      }}
    />
  </StyledSection>
)

export default WrappedForm
