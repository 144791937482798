import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import mq from 'styled/mediaQuery'

type PropsType = {
  children?: React.ReactNode
  className?: string
  narrow?: boolean
}

const Wrap = styled.div<{ narrow?: PropsType['narrow'] }>`
  max-width: 1304px;

  ${mq.to(mq.devices.tablet)} {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
`

const Container = ({ children, className, narrow }: PropsType) => {
  const narrowChildren = (
    <div className="row">
      <div className="col-xs-12 col-sm-10 col-sm-offset-1">{children}</div>
    </div>
  )

  return (
    <Wrap
      className={classnames({
        'container-fluid': true,
        [`${className as string}`]: className,
      })}
    >
      {narrow ? narrowChildren : children}
    </Wrap>
  )
}

export default Container
