import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Container from 'layout/Container'
import colors from 'styled/colors'

const LinksWrap = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
`

const StyledLink = styled.div`
  position: relative;
  padding: 0 1em;

  & + & {
    border-left: 1px solid ${colors.white};
  }

  > a {
    display: inline-block;
    color: ${colors.white};
    text-decoration: none;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`

const Footer = () => (
  <Container>
    <LinksWrap>
      <StyledLink>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </StyledLink>
      <StyledLink>
        <Link to="/terms-of-service">Terms of Service</Link>
      </StyledLink>
    </LinksWrap>
  </Container>
)

export default Footer
