import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Container from 'layout/Container'
import { TitleBig, TitleSmall } from 'components/Typography'
import mq from 'styled/mediaQuery'
import colors from 'styled/colors'

const Wrap = styled.section`
  margin-top: 50px;
  margin-bottom: 50px;
  color: ${colors.white};

  ${mq.from(mq.devices.tablet)} {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`

const Title = styled(TitleBig)`
  margin-top: 0;
  line-height: 1.36;

  &:last-child {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

const Subtitle = styled(TitleSmall)`
  max-width: 820px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Header = styled(Container)`
  padding-bottom: 2rem;
  text-align: center;

  ${mq.from(mq.devices.tablet)} {
    padding-bottom: 4.375rem;
  }
`

type PropsType = {
  id?: string
  title?: string
  subtitle?: string
  children: React.ReactNode
  className?: string
  classNames?: {
    wrap?: string
    header?: string
    title?: string
    subtitle?: string
  }
}

const Section = ({
  id,
  title,
  subtitle,
  children,
  className,
  classNames,
}: PropsType) => (
  <Wrap
    id={id}
    className={classnames(className, classNames && classNames.wrap)}
  >
    {title && (
      <Header narrow className={classNames && classNames.header}>
        <Title className={classNames && classNames.title}>{title}</Title>
        <Subtitle className={classNames && classNames.subtitle}>
          {subtitle}
        </Subtitle>
      </Header>
    )}
    {children}
  </Wrap>
)

export default Section
